import { forwardRef, ReactNode, RefObject } from "react"
import { clsx } from "clsx"
import { CompoundedComponent, IFormControl } from "@/types"
import { Controller, FieldValues } from "react-hook-form"
import "./styles/input.sass"

interface InputProps extends Omit<React.InputHTMLAttributes<HTMLInputElement>, "required"> {
  label?: string
  leftIcon?: ReactNode
  rightIcon?: ReactNode
  error?: string
  rows?: number
  classNames?: Partial<{
    label: string
  }>
}

export const Input = forwardRef<HTMLInputElement | HTMLTextAreaElement, InputProps>(
  ({ className, classNames, label, leftIcon, rows, rightIcon, error, ...props }, ref) => {
    return (
      <div className={clsx("custom-input", className)}>
        {label && <span className="custom-input__label">{label}</span>}
        <label className={clsx("custom-input__input", error != null && "custom-input__error", label != null && "mt-2.5", classNames?.label)}>
          {leftIcon && <span className="custom-input__left-icon">{leftIcon}</span>}
          {rows == null ? (
            <input {...props} ref={ref as RefObject<HTMLInputElement>} />
          ) : (
            //@ts-ignore
            <textarea {...props} ref={ref as RefObject<HTMLTextAreaElement>} rows={rows} />
          )}
          {rightIcon && <span className="custom-input__right-icon">{rightIcon}</span>}
        </label>
        {error && <span className="custom-input__error-message">{error}</span>}
      </div>
    )
  }
) as CompoundedComponent<InputProps, HTMLInputElement, { Form: typeof FormInput }>

export const FormInput = <T extends FieldValues>({ control, name, required, rules, ...inputProps }: IFormControl<T> & InputProps) => {
  return (
    <Controller
      {...{
        control,
        name,
        rules: {
          required: {
            value: required ?? false,
            message: "This field is required",
          },
          ...rules,
        },
      }}
      render={({ field, fieldState: { error } }) => <Input {...inputProps} {...field} error={error?.message} />}
    />
  )
}

Input.Form = FormInput
