import { useSearch } from "@/hooks"
import { lazy, useEffect } from "react"
import { useNavigate } from "react-router-dom"

const NavigateToSelect = () => {
  const navigate = useNavigate()

  const { pathWithQuery, pathname } = useSearch("/widgets/order-card/select")

  useEffect(() => {
    if (pathname === "/widgets/order-card") {
      navigate(pathWithQuery, { replace: true })
    }
  }, [navigate, pathWithQuery, pathname])

  return null
}

export const orderCardRoutes = [
  {
    path: "/widgets/order-card",
    element: <NavigateToSelect />,
  },
  {
    path: "select",
    Component: lazy(async () => await import("../../Dashboard/OrderCard/Sections/SelectCards")),
  },
  {
    path: "payment-method",
    Component: lazy(async () => await import("../../Dashboard/OrderCard/Sections/PaymentMethod")),
  },
  {
    path: "confirmation",
    Component: lazy(async () => await import("../../Dashboard/OrderCard/Sections/OrderConfirmation")),
  },
  {
    path: "instruction",
    Component: lazy(async () => await import("../../Dashboard/OrderCard/Sections/OrderInstruction")),
  },
  {
    path: "transaction-id",
    Component: lazy(async () => await import("../../Dashboard/OrderCard/Sections/TransactionID")),
  },
]
