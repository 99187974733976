import clsx from "clsx"
import { PropsWithChildren } from "react"

interface Props {
  step: number
  className?: string
}

export const StepChip = ({ className, step, children }: Props & PropsWithChildren) => {
  return (
    <div className={clsx("p-3 flex items-center gap-2.5 bg-paper border border-gray-200 rounded-[11px]", className)}>
      <div className="bg-main text-white flex self-start items-center justify-center rounded-full w-8 h-8 text-xl min-w-8 font-bold">{step}</div>
      <span className="text-base">{children}</span>
    </div>
  )
}
