import clsx from "clsx"
import React, { ReactNode } from "react"
import "./styles/checkbox.sass"
import { CompoundedComponent, IFormControl } from "@/types"
import { Controller, FieldValues } from "react-hook-form"

interface ICheckboxProps extends React.InputHTMLAttributes<HTMLInputElement> {
  label?: ReactNode
  rtl?: boolean
  className?: string
  error?: string
}
export const Checkbox = React.forwardRef<HTMLInputElement, ICheckboxProps>(({ label, rtl, className, error, ...props }, ref) => {
  return (
    <div className="custom-checkbox__wrapper">
      <label className={clsx("custom-checkbox", className)}>
        <input type="checkbox" {...props} ref={ref} />
        {label != null && <span>{label}</span>}
      </label>
      {error != null && <span className="custom-checkbox__error">{error}</span>}
    </div>
  )
}) as CompoundedComponent<ICheckboxProps, HTMLInputElement, { Form: typeof FormCheckbox }>

const FormCheckbox = <T extends FieldValues>({ control, name, required, rules, ...props }: IFormControl<T> & ICheckboxProps) => {
  return (
    <Controller
      {...{
        control,
        name,
        rules: {
          required: {
            value: required ?? false,
            message: "This field is required",
            ...rules,
          },
        },
      }}
      render={({ field, fieldState: { error } }) => <Checkbox {...field} checked={field.value} {...props} error={error?.message} />}
    />
  )
}

Checkbox.Form = FormCheckbox
