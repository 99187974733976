import { PropsWithChildren, ReactNode, useEffect, useRef } from "react"
import "./style.sass"
import { createPortal } from "react-dom"
import { Icon } from "../Icons"

interface ModalProps extends PropsWithChildren {
  show: boolean
  onClose: VoidFunction
  title?: ReactNode
  closeable?: boolean
}
export const Modal = ({ title, closeable = true, show, onClose, children }: ModalProps) => {
  const wrapperRef = useRef<HTMLDivElement>(null)
  const contentRef = useRef<HTMLDivElement>(null)
  useEffect(() => {
    const wrapper = wrapperRef.current
    const content = contentRef.current
    if (wrapper == null || content == null) return
    setTimeout(() => {
      wrapper.classList.add("custom-modal-show")
      content.classList.add("custom-modal__content-show")
    }, 20)
  }, [show])

  if (!show) return null
  return createPortal(
    <div ref={wrapperRef} className="custom-modal" onClick={onClose}>
      <div
        ref={contentRef}
        style={{ maxWidth: "650px", padding: 0, display: "flex", flexDirection: "column", maxHeight: "100%" }}
        className="custom-modal__content"
        onClick={(e) => e.stopPropagation()}
      >
        <div
          className="flex items-start md:items-center justify-between text-[18px] text-black font-bold gap-6"
          style={{ borderBottom: "1px solid #d5d8de", padding: "20px 20px 12px 20px" }}
        >
          {title}
          {closeable && (
            <div className="hover:bg-gray-100 p-2 cursor-pointer" onClick={onClose}>
              <Icon icon="Close" />
            </div>
          )}
        </div>

        <div style={{ padding: "12px 20px 20px 20px", overflowY: "auto" }}>{children}</div>
      </div>
    </div>,
    document.getElementById("root")!
  )
}
