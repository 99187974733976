import { StepChip } from "@/components/StepChip"
import "./style.sass"
import "react-intl-tel-input-18/dist/main.css"
import { Input } from "@/components/Form/Input"
import { Icon } from "@/components/Icons"
import { Button } from "@/components/Button"
import { Selector } from "@/components/Selector"
import { useOrderCard } from "../../OrderCardProvider"
import { InputHTMLAttributes, useCallback, useEffect, useRef } from "react"
import { useController, useFormContext, useWatch } from "react-hook-form"
import { isValidPhoneNumber } from "libphonenumber-js"
import clsx from "clsx"
import { CountrySelect, getExcludedCountriesList } from "@/components/NewSelector/CoutrySelect"
import { Modal } from "@/components/Modal"
import { IOption } from "@/types"
import { useLocation, useNavigate } from "react-router-dom"
import { useSearch } from "@/hooks"
import { Checkbox } from "@/components/Form/Checkbox"

export const WITH_MASTERCARD = false

const Country = ({
  name,
  cardType,
  ...rest
}: {
  label?: string
  placeholder?: string
  name: string
  disabled?: boolean
  cardType: IOption | null
}) => {
  const {
    control,
    formState: { errors },
  } = useFormContext()
  const {
    field: { ref, ...field },
  } = useController({
    name,
    control,
    rules: {
      required: "This field is required.",
    },
  })

  return (
    <div className="mb-[16px] w-full">
      <CountrySelect
        {...field}
        {...rest}
        withFlag
        cardType={cardType}
        searchable
        value={typeof field.value === "string" ? null : field.value}
        error={errors?.[name]?.message as string}
      />
    </div>
  )
}

export const Phone = ({ cardType, required }: { cardType: IOption | null; required: boolean }) => {
  const ref = useRef<HTMLInputElement>(null)
  const {
    control,
    setValue,
    clearErrors,
    formState: { errors },
  } = useFormContext()

  const { field } = useController({ name: "phoneCode", control })
  const { field: numberField } = useController({
    name: "phoneNumber",
    control,
    rules: {
      required: required ? "This field is required." : false,
      validate: (v) => (isValidPhoneNumber(v) || !required ? undefined : "Invalid phone number"),
    },
  })

  useEffect(() => {
    if (!required) {
      clearErrors(["phoneNumber, phoneCode"])
    }
  }, [clearErrors, required])

  useEffect(() => {
    if (field.value == null) {
      setValue("phoneCode", {
        label: "United Kingdom +44",
        meta: { iso2: "gb", dialCode: "44", priority: 0 },
        dialCode: "44",
        iso2: "gb",
        priority: 0,
        value: "gb",
      })
      setValue("phoneNumber", "+44")
    }
  }, [field.value, setValue])

  const handleChangePhone = useCallback<NonNullable<InputHTMLAttributes<HTMLInputElement>["onChange"]>>(
    (event) => {
      if (event.target.value.startsWith("+")) {
        numberField.onChange(event)
      } else {
        numberField.onChange(`+${event.target.value}`)
      }
    },
    [numberField]
  )

  const hasError = errors.phoneNumber && (required || (!required && errors.phoneNumber.message !== "This field is required."))

  return (
    <div className="flex flex-col mb-[16px]">
      <div className="flex" style={{ gap: "10px" }}>
        <CountrySelect
          withDialCode
          withFlag
          cardType={cardType}
          value={field.value ?? null}
          onlyFlag
          phoneNumber={numberField.value}
          searchable={false}
          onChange={(value) => {
            if (value != null) {
              setTimeout(() => {
                ref.current?.focus()
              }, 100)
              field.onChange(value)
              // @ts-expect-error
              numberField.onChange(value.meta.dialCode != null ? `+${value.meta.dialCode}` : "")
            }
          }}
        />
        <div className="custom-input flex flex-col" style={{ width: "100%" }}>
          <label className={clsx("custom-input__input", hasError && "custom-input__error")}>
            <input ref={ref} type="tel" name="phoneNumber" placeholder="Phone Number" value={numberField.value} onChange={handleChangePhone} />
          </label>
          {hasError && <div className="custom-input__error-message">{errors?.phoneNumber?.message as string}</div>}
        </div>
      </div>
    </div>
  )
}

const SelectCards = () => {
  const { model, cardTypes, flatPricesOptions, activeStep, setActiveStep, brandInfo, isWidget, isVisaCardType, isUseMyPhoneNumber } = useOrderCard()

  const { hash, pathname } = useLocation()
  const navigate = useNavigate()

  const { pathWithQuery } = useSearch(pathname)
  const { pathWithQuery: visaModalPath } = useSearch(pathname, "visa-modal")
  const { pathWithQuery: mastercardModalPath } = useSearch(pathname, "mastercard-modal")

  const [country, cardType] = useWatch({ name: ["country", "cardType"] })

  useEffect(() => {
    if (activeStep !== 1) {
      setActiveStep(1)
    }
  }, [activeStep, setActiveStep])

  const renderModalCountries = useCallback(
    (type: "visa" | "mastercard") =>
      // @ts-expect-error
      getExcludedCountriesList({ value: type }).map((x) => {
        const nameArr = (x.name ?? "").split("")

        const index = nameArr.findIndex((x) => x === "(")

        const name = index >= 0 ? nameArr.splice(0, index).join("") : x.name

        return (
          <div key={x.iso2} style={{ display: "flex", alignItems: "center", gap: "8px" }}>
            <div className={`iti-flag ${x.iso2} min-w-[20px]`} />
            <span>{name}</span>
          </div>
        )
      }),
    []
  )

  if (model == null) {
    return null
  }

  return (
    <>
      <div className="select-cards">
        <div className="select-cards__left">
          <StepChip step={1} className="with-ol">
            Please provide the information for which the card will be issued
            <ul>
              <li>Enter your current billing address.</li>
              <li>The address entered must match the one used when making purchases online with your virtual card.</li>
              <li>
                Address validation occurs during online transactions (TIP: This is similar to the address validation that takes place when you shop
                online with a credit card).
              </li>
              <li style={{ color: "#ef4444" }}>Please note! Address mismatches can cause difficulties with ApplePay and GooglePay</li>
            </ul>
          </StepChip>
          <div className="flex my-5 mb-8 gap-3 form-personal">
            <div className="flex flex-col flex-1 gap-3">
              {/*
            //@ts-expect-error */}
              <Input.Form
                {...{
                  control: model.control,
                  name: "firstName",
                  rules: {
                    validate: (v) => (/^[a-zA-Z0-9\s]+$/.test(v) ? undefined : "Only latin characters"),
                  },
                }}
                className="mb-[16px]"
                placeholder="First Name"
                required
              />
              {/*
            //@ts-expect-error */}
              <Input.Form
                {...{
                  control: model.control,
                  name: "lastName",
                  rules: {
                    validate: (v) => (/^[a-zA-Z0-9\s]+$/.test(v) ? undefined : "Only latin characters"),
                  },
                }}
                className="mb-[16px]"
                placeholder="Last Name"
                required
              />
              {/*
            //@ts-expect-error */}
              <Input.Form
                {...{
                  control: model.control,
                  name: "email",
                  rules: {
                    validate: (value) =>
                      // eslint-disable-next-line no-control-regex
                      /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/.test(
                        value
                      )
                        ? undefined
                        : "Enter valid email",
                  },
                }}
                className="mb-[16px]"
                placeholder="Please Enter Your E-mail"
                required
              />
              <Phone cardType={cardType} required={isUseMyPhoneNumber == null || isUseMyPhoneNumber} />
              <Country cardType={cardType} name="userCountry" placeholder="Select Country" />
            </div>
            <div className="flex flex-col flex-1 gap-3">
              {/*
            //@ts-expect-error */}
              <Input.Form
                {...{
                  control: model.control,
                  name: "state",
                  rules: {
                    validate: (v) => (/^[a-zA-Z0-9\s]+$/.test(v) ? undefined : "Only latin characters"),
                  },
                }}
                className="mb-[16px]"
                placeholder="State"
                required
              />
              {/*
            //@ts-expect-error */}
              <Input.Form
                {...{
                  control: model.control,
                  name: "city",
                  rules: {
                    validate: (v) => (/^[a-zA-Z0-9\s]+$/.test(v) ? undefined : "Only latin characters"),
                  },
                }}
                className="mb-[16px]"
                placeholder="City"
                required
              />
              {/*
            //@ts-expect-error */}
              <Input.Form
                {...{
                  control: model.control,
                  name: "address",
                  rules: {
                    validate: (v) => (/^[a-zA-Z0-9\s]+$/.test(v) ? undefined : "Only latin characters"),
                  },
                }}
                className="mb-[16px]"
                placeholder="Street Address"
                required
              />
              <Input.Form
                {...{
                  control: model.control,
                  name: "apartment",
                }}
                className="mb-[16px]"
                placeholder="Apt/ suite (Optional)"
              />
              {/*
            //@ts-expect-error */}
              <Input.Form
                {...{
                  control: model.control,
                  name: "zip",
                  rules: {
                    validate: (v) => (/^[a-zA-Z0-9]+$/.test(v) ? undefined : "Only latin characters"),
                  },
                }}
                placeholder="Zip Code"
                required
              />
            </div>
          </div>
          <div style={{ display: "flex", gap: "12px", marginBottom: "12px" }}>
            <div style={{ width: "16px", paddingTop: "4px" }}>
              <Icon icon="InformationCircle" size={16} color="#ef4444" />
            </div>

            <span style={{ color: "#ef4444", cursor: "pointer" }} onClick={() => navigate(visaModalPath)}>
              Please, <strong style={{ textDecoration: "underline" }}>read the List of Prohibited Countries for Visa</strong>, transactions in these
              countries are prohibited and your card may be blocked!
            </span>
          </div>
          {WITH_MASTERCARD ? (
            <div style={{ display: "flex", gap: "12px" }}>
              <div style={{ width: "16px", paddingTop: "4px" }}>
                <Icon icon="InformationCircle" size={16} color="#ef4444" />
              </div>

              <span style={{ color: "#ef4444", cursor: "pointer" }} onClick={() => navigate(mastercardModalPath)}>
                Please, <strong style={{ textDecoration: "underline" }}>read the List of Prohibited Countries for Mastercard</strong>, transactions in
                these countries are prohibited and your card may be blocked!
              </span>
            </div>
          ) : null}
        </div>
        <div className="select-cards__right flex flex-col">
          {isWidget ? null : (
            <>
              <StepChip step={2}>Please enter the referral code</StepChip>
              <Input.Form
                {...{ control: model.control, name: "code" }}
                className="my-5 mb-8"
                placeholder="Please enter your code"
                leftIcon={<Icon icon="Tag" />}
              />
            </>
          )}
          <StepChip step={isWidget ? 2 : 3}>Please select the type of card and card denomination</StepChip>
          <div className="gap-3 flex flex-col my-7">
            <div className="gap-3 flex flex-col md:flex-row">
              <div className="md:w-[200px]">
                <Selector.Form {...{ control: model.control, name: `cardType` }} label="Card Type" options={cardTypes} required />
              </div>
              <Country cardType={cardType} name="country" label="Country of card issuer" disabled={isVisaCardType} />
            </div>
            <div className="flex items-end denomination-form" style={{ marginBottom: "12px" }}>
              <Input.Form
                {...{
                  control: model.control,
                  name: `denomination`,
                  rules: {
                    min: brandInfo?.min ? { value: brandInfo.min, message: `Minimum ${brandInfo.min}` } : undefined,
                    max: brandInfo?.max ? { value: brandInfo.max, message: `Maximum ${brandInfo.max}` } : undefined,
                  },
                }}
                type="number"
                label="Card Denomination"
                disabled={country?.value == null || cardType?.value == null}
                required
                className="flex-1 card-denomination"
                onKeyDown={(e) => {
                  if (e.code === "KeyE" || e.code === "Equal" || e.code === "Minus") {
                    e.preventDefault()
                  }
                }}
              />
              <Selector.Form
                {...{ control: model.control, name: `currency`, rules: { required: "empty_error" } }}
                disabled={country?.value == null || cardType?.value == null}
                options={flatPricesOptions}
                required
                className="w-[100px] currency"
              />
            </div>
            {cardType?.value === "mastercard" ? (
              <div style={{ padding: "20px", borderRadius: "6px", backgroundColor: "#F5F7FC" }}>
                <ul style={{ listStyle: "unset", paddingLeft: "20px" }}>
                  <li>Cards are issued for amounts from $10 to $999 and cannot be recharged secondary.</li>
                  <li>Cards do not support 3DS, so we recommend using for offline payments via Apple Pay and Google Pay.</li>
                  <li>Limits: no more than $5,000 can be issued per profile (customer Email) per 24 hours.</li>
                  <li>
                    For Mastercard card issue, please provide any convenient address, except for countries from the{" "}
                    <strong onClick={() => navigate(mastercardModalPath)} style={{ textDecoration: "underline", cursor: "pointer" }}>
                      Prohibited Countries List
                    </strong>
                    !
                  </li>
                  <li>For Mastercard card issue, please provide any convenient Phone Number</li>
                </ul>
              </div>
            ) : null}
            {cardType?.value === "visa" ? (
              <div style={{ padding: "20px", borderRadius: "6px", backgroundColor: "#F5F7FC" }}>
                <ul style={{ listStyle: "unset", paddingLeft: "20px", marginBottom: "24px" }}>
                  <li>Cards are issued for the amount from 10$ to 5000$, and can not be replenished secondary.</li>
                  <li>Cards support 3DS, and can be used both for offline payments, through Apple Pay and Google Pay, and for online payments.</li>
                  <li>Limits: no more than $5,000 can be issued per profile (customer mail) per 24 hours.</li>
                </ul>

                <p style={{ marginBottom: "12px" }}>
                  <strong>CAUTION:</strong> for the correct issuance of Visa Card, a valid phone number (USA only) and address (USA only) is required.
                  Phone Number will be used to receive 3DS messages, as well as when adding a card to Apple Pay / Google Pay. Please, read the{" "}
                  <strong onClick={() => navigate(visaModalPath)} style={{ textDecoration: "underline", cursor: "pointer" }}>
                    List of Prohibited Countries
                  </strong>
                  , transactions in these countries are prohibited and your card may be blocked!
                </p>

                <p style={{ marginBottom: "12px" }}>
                  <strong>OPTIONS:</strong> You may use the service phone number (free for 1 month), in this case SMS OTPs will be sent directly to
                  your Email. Alternatively, you can use your own phone number (USA).
                </p>

                <div style={{ display: "flex", flexDirection: "column", gap: "8px" }}>
                  <Checkbox
                    onClick={() => model.setValue("isUseMyPhoneNumber", false)}
                    checked={isUseMyPhoneNumber === false}
                    label="I want to use the service phone number"
                    error={isUseMyPhoneNumber == null && model.formState.submitCount > 0 ? "" : undefined}
                  />
                  <Checkbox
                    error={isUseMyPhoneNumber == null && model.formState.submitCount > 0 ? "" : undefined}
                    onClick={() => model.setValue("isUseMyPhoneNumber", true)}
                    checked={isUseMyPhoneNumber === true}
                    label="I want to use my owm US phone number"
                  />
                </div>
              </div>
            ) : null}
          </div>
          <Button className="min-w-56 self-end" type="submit">
            Next
          </Button>
        </div>
      </div>
      <Modal onClose={() => navigate(pathWithQuery)} show={hash === "#visa-modal"} title={`Blocked Countries for Visa`}>
        <div className="flex flex-col">
          <span style={{ marginBottom: "12px", color: "#ef4444" }}>
            Please check the list of closed countries for Visa here. Transaction in these countries are prohibited and your card may be blocked.
          </span>

          {renderModalCountries("visa")}
        </div>
      </Modal>

      <Modal onClose={() => navigate(pathWithQuery)} show={hash === "#mastercard-modal"} title={`Blocked Countries for Mastercard`}>
        <div className="flex flex-col">
          <span style={{ marginBottom: "12px", color: "#ef4444" }}>
            Please check the list of closed countries for Mastercard here. Transaction in these countries are prohibited and your card may be blocked.
          </span>

          {renderModalCountries("mastercard")}
        </div>
      </Modal>
    </>
  )
}

export default SelectCards
